import { InjectionToken } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

import { DeviceDetection } from '@shure/cloud/shared/models/devices';

export const DEVICE_DISCOVERY_CLAIMING_SERVICE = new InjectionToken<DeviceClaimingDiscoveryApiService>(
	'DEVICE_DISCOVERY_CLAIMING_SERVICE'
);

export abstract class DeviceClaimingDiscoveryApiService {
	public abstract unclaimedDevices$: BehaviorSubject<DeviceDetection[]>;
	public abstract scanInProgess$: BehaviorSubject<boolean>;
	public abstract deviceDiscoveryError$: ReplaySubject<string>;
	public abstract newScan(): void;
	public abstract destroy(): void;
}
