import { InjectionToken } from '@angular/core';

import { DecorationExport, DecorationType } from './decoration.models';

export const DECORATION_PROVIDERS = new InjectionToken<DecorationProvider[]>('DecorationProviders');

export interface DecorationProvider {
	decorationSupportedTypes(): DecorationType[];
	decorationClone(id: string, toId: string): Promise<void>;
	decorationSetExport(id: string, decoration: DecorationExport): Promise<void>;
	decorationGetExport(id: string): Promise<DecorationExport[]>;
	decorationDelete(id: string): Promise<void>;
}
