import { BehaviorSubject, Observable } from 'rxjs';

import {
	ClaimResults,
	DeviceClaimProgress,
	DeletePendingClaimBatchResult,
	DeviceDetection,
	MacSerialNumberDevice,
	PendingClaim
} from '@shure/cloud/shared/models/devices';

export abstract class DeviceClaimingApiService {
	public abstract claimOpInProgress$: BehaviorSubject<boolean>;
	public abstract claimDevicesThrottled$(
		devices: DeviceDetection[]
	): Observable<DeviceClaimProgress<DeviceDetection>>;
	public abstract claimDevicesByMacSerialThrottled$(
		devices: MacSerialNumberDevice[]
	): Observable<DeviceClaimProgress<MacSerialNumberDevice>>;
	public abstract deletePendingClaimsThrottled$(deviceNodeIds: string[]): Observable<DeletePendingClaimBatchResult>;
	public abstract getPendingClaims$(): Observable<PendingClaim[]>;
	public abstract unClaimDevicesThrottled$(deviceNodeIds: string[]): Observable<ClaimResults>;
}
