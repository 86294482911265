import { Subscription } from 'rxjs';
export interface SubscriptionManagerConfigCreate {
	id: string;
	retryCallback: () => void;
	resetRetryDelay: () => void;
}
export interface SubscriptionManagerConfig {
	create: (config: SubscriptionManagerConfigCreate) => Subscription;
	retryWaitMs?: number;
}

interface SubscriptionInfo {
	subscription: Subscription;
	queryIds: Set<string>;
}

const initialRetryDelay = 1000; // 1 second

const maxRetryDelay = 4096 * 1000; // ~68 minutes

export class SubscriptionManager {
	private readonly subscriptions = new Map<string, SubscriptionInfo>();
	private readonly retryDelay = new Map<string, number>();

	constructor(private readonly config: SubscriptionManagerConfig) {}

	public subscribe(queryId: string, keyArg: string | string[], prune = true): void {
		const keys = typeof keyArg === 'string' ? [keyArg] : keyArg;
		const keysAsSet = new Set(keys);
		const keysToRemove = prune ? new Set([...this.subscriptions.keys()].filter((x) => !keysAsSet.has(x))) : [];
		keys.forEach((key) => this.addKey(queryId, key));
		keysToRemove.forEach((key) => this.removeKey(queryId, key));
	}

	public unsubscribeAllQuery(queryId: string): void {
		this.subscriptions.forEach((subscriptionInfo, key) => {
			if (subscriptionInfo.queryIds.has(queryId)) {
				this.removeKey(queryId, key);
			}
		});
	}

	public unsubscribeAll(): void {
		this.subscriptions.forEach((subscriptionInfo, _) => {
			subscriptionInfo.subscription.unsubscribe();
		});
		this.subscriptions.clear();
		this.retryDelay.clear();
	}

	public removeKey(queryId: string, key: string): void {
		const subscriptionInfo = this.subscriptions.get(key);
		if (subscriptionInfo) {
			const queryIds = new Set<string>([...subscriptionInfo.queryIds.keys()].filter((qId) => qId !== queryId));
			this.subscriptions.delete(key);
			if (queryIds.size > 0) {
				this.subscriptions.set(key, { ...subscriptionInfo, queryIds });
			} else {
				subscriptionInfo.subscription.unsubscribe();
			}
		}

		const retryDelayKey = this.buildRetryDelayKey(queryId, key);
		if (this.retryDelay.has(retryDelayKey)) {
			this.retryDelay.delete(retryDelayKey);
		}
	}

	private addKey(queryId: string, key: string): void {
		const subscriptionInfo = this.subscriptions.get(key);
		if (subscriptionInfo) {
			subscriptionInfo.queryIds.add(queryId);
		} else {
			const subscription = this.config.create({
				id: key,
				retryCallback: () => this.handleSubscriptionRetry(queryId, key),
				resetRetryDelay: () => {
					this.retryDelay.set(this.buildRetryDelayKey(queryId, key), initialRetryDelay);
				}
			});
			this.subscriptions.set(key, { subscription, queryIds: new Set<string>([queryId]) });
		}
	}

	private handleSubscriptionRetry(queryId: string, key: string): void {
		const retryDelayKey = this.buildRetryDelayKey(queryId, key);
		const delay = this.retryDelay.get(retryDelayKey) ?? initialRetryDelay;

		setTimeout(() => {
			this.reCreateSubscription(queryId, key);
		}, delay);

		if (delay < maxRetryDelay) {
			this.retryDelay.set(retryDelayKey, delay * 2);
		}
	}

	private reCreateSubscription(queryId: string, key: string): void {
		const subscriptionInfo = this.subscriptions.get(key);
		if (subscriptionInfo && subscriptionInfo.queryIds.has(queryId)) {
			subscriptionInfo.subscription.unsubscribe();
			const subscription = this.config.create({
				id: key,
				retryCallback: () => this.handleSubscriptionRetry(queryId, key),
				resetRetryDelay: () => {
					this.retryDelay.set(this.buildRetryDelayKey(queryId, key), initialRetryDelay);
				}
			});
			this.subscriptions.set(key, { ...subscriptionInfo, subscription });
		}
	}

	private buildRetryDelayKey(queryId: string, key: string): string {
		return `${queryId}${key}`;
	}
}
