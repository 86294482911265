import { InventoryDevice } from '@shure/cloud/shared/models/devices';

export abstract class DeviceRelationshipService {
	/**
	 * Associate the given host device with each of its proxied devices.
	 * @param hostDevice the host device for the proxied devices that will be registered
	 */
	public abstract registerProxiedDevices(hostDevice: InventoryDevice): void;

	/**
	 * Remove associations for a device.
	 * @param deviceId the device ID to remove
	 */
	public abstract removeDevice(deviceId: string): void;

	/**
	 * Get the associated host device ID for a proxied device. If the device is not proxied or the
	 * host has been removed, this will return `undefined`.
	 * @param proxiedDeviceId the ID of the proxied device
	 */
	public abstract getHostDevice(proxiedDeviceId: string): string | undefined;
}
