import { ApolloClientOptions, ApolloLink, PossibleTypesMap, from } from '@apollo/client/core';

import { MessagePortLink } from '@shure/application-apollo-link-web';

import { ApolloCacheFactory } from './apollo-cache-factory.service';
import { ApolloConnectionService } from './apollo-connection.service';
export class MessagePortApolloConnectionService extends ApolloConnectionService {
	constructor(
		private readonly name: string,
		private readonly possibleTypes: PossibleTypesMap,
		private readonly apolloCacheFactory: ApolloCacheFactory,
		private readonly messagePort: MessagePort,
		private readonly apolloLinks: ApolloLink[]
	) {
		super();
	}

	public createApolloClientOptions(): ApolloClientOptions<unknown> {
		return {
			cache: this.apolloCacheFactory.createCache(this.name, this.possibleTypes),
			link: from([...this.apolloLinks, new MessagePortLink(this.messagePort)])
		};
	}
}
