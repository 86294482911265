import assertNever from 'assert-never';
import { Duration } from 'ts-luxon';

import { IpMode, DeviceState } from '@shure/cloud/shared/models/devices';
import { DeviceStatus } from '@shure/shared/angular/data-access/system-api/core';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

import { IpMode as SysApiMode } from '../graphql/generated/cloud-sys-api';
// wangth: The usage of this value should be removed, and replaced with test for isMissing attribute,
// which should go all the way to UI.
export const MISSING_VALUE = '..';

export function mapFeatureValueFromSysApi(value?: string | null): string {
	return value ?? MISSING_VALUE;
}

export function mapIpModeFromSysApi(value: SysApiMode | undefined): IpMode | undefined {
	if (value === undefined) {
		return undefined;
	}
	switch (value) {
		case SysApiMode.Dhcp:
			return IpMode.DHCP;
		case SysApiMode.Static:
			return IpMode.STATIC;
		default:
			assertNever(value);
	}
}

/**
 * TODO: Remove this mapping and use the value directly from GraphQL. We want to make this change
 * as part of a larger refactoring to create a default DeviceImageProvider, which will allow us
 * to remove all of the individual device behavior projects:
 * https://bitbucket.shure.com/projects/WPLT/repos/shure.web.apps/pull-requests/3859/overview?commentId=176994
 */
export function mapDeviceModelFromSysApi(model: string): DeviceModel {
	switch (model) {
		case 'MXA920-S':
			return DeviceModel.MXA920S;
		case 'MXA920-R':
			return DeviceModel.MXA920R;
		case 'P300':
			return DeviceModel.P300;
		case 'MXWAPXD2':
			return DeviceModel.MXWAPXD2;
		case 'MXA710-4FT':
			return DeviceModel.MXA7104FT;
		case 'MXA710-2FT':
			return DeviceModel.MXA7102FT;
		case 'ANIUSB-MATRIX':
			return DeviceModel.ANIUSB;
		case 'MXA901-R':
			return DeviceModel.MXA901R;
		case 'MXA901-S':
			return DeviceModel.MXA901S;
		case 'MXA902-S':
			return DeviceModel.MXA902S;
		case 'MXW1X':
			return DeviceModel.MXW1X;
		case 'MXW2X':
			return DeviceModel.MXW2X;
		case 'MXW6X':
			return DeviceModel.MXW6X;
		case 'MXW8X':
			return DeviceModel.MXW8X;
		case 'IMX-Room':
			return DeviceModel.IMXROOM;
		case 'MXN5-C':
			return DeviceModel.MXN5C;
		case 'ANX4':
			return DeviceModel.MCR;
		case 'AD600':
			return DeviceModel.AD600;
		case 'ADTD':
			return DeviceModel.ADTD;
		case 'ADTQ':
			return DeviceModel.ADTQ;
		default:
			return <DeviceModel>model;
	}
}

export function mapDeviceStateFromSysApi(deviceStatus: DeviceStatus): DeviceState {
	switch (deviceStatus) {
		case DeviceStatus.Discovered:
			return DeviceState.Discovered;
		case DeviceStatus.Error:
			return DeviceState.Error;
		case DeviceStatus.Offline:
			return DeviceState.Offline;
		case DeviceStatus.Online:
			return DeviceState.Online;
		default:
			return DeviceState.Error;
	}
}

export function mapUptimeDurationFromSysApi(duration: string | undefined): string {
	if (!duration || duration === MISSING_VALUE) return '';

	// Converts ISO 8601 time duration to [h]:mm:ss
	return Duration.fromISO(duration).toFormat('hh:mm:ss');
}
