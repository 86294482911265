import { Injectable } from '@angular/core';
import { Subscription as ApolloSubscription } from 'apollo-angular';
import { Subscription } from 'rxjs';

import { ILogger } from '@shure/shared/angular/utils/logging';

import { NodeChangeType } from '../../generated/system-api.generated';

import { GqlSubscriptionParser, ParsedSubscription, SubscribedField } from './gql-subscription-parser';

export interface SubscriptionHandler {
	/**
	 * Handler name (for logging and debugging purposes only)
	 */
	name: string;

	/**
	 * The change types this handler will be subscribing to when subscribing
	 */
	readonly changeTypes: NodeChangeType[];

	/**
	 * The subscription field (top node) of a parsed out GQL selection tree
	 * which this handler will be subscribing to when subscribing.
	 */
	readonly gqlSelection: SubscribedField;

	/**
	 * An opague ID uniquely identifying the GQL subscription selection
	 * Two handler instances with the same gqlSelectionId has the exact same selection. Two
	 * handle instances with difference gqlSelectionId may, or may not, have the exact same
	 * selection.
	 */
	readonly gqlSelectionId: object;

	/**
	 * Subscribe to the given target object
	 */
	subscribe(targetId: string): Subscription;

	/**
	 * Unsubscribe a previously opened subscription
	 */
	unsubscribe(targetId: string, subscription: Subscription): void;
}

/**
 * An active subscription on a target - i.e. actually subscribed via GQL
 */
interface ActiveSubscription {
	handler: SubscriptionHandler;
	subscription: Subscription;
}

/**
 * Subscription management context for a target object
 */
interface TargetContext {
	targetId: string;

	/**
	 * All requested subscriptions on the target
	 */
	subscribers: Set<SubscriptionHandler>;

	/**
	 * Subscriptions actively opened with the target.
	 * This is a subset of the "subscribers" list (enough to cover all requested properties).
	 */
	activeSubscriptions: Map<SubscriptionHandler, ActiveSubscription>;
}

enum SelectionComparison {
	NotContained,
	Contained,
	Equal
}

interface CachedGqlComparisons {
	/**
	 * Comparison results with other GQL selection trees.
	 *
	 * Lookup key is the DocumentNode of the GQL document describing the selection since
	 * that is the same for multiple usages of the same GQL subscription query.
	 */
	comparisons: Map<object, SelectionComparison>;
}

@Injectable({ providedIn: 'root' })
export class SubscriptionManagerService {
	private readonly logger: ILogger;

	private readonly parsedGqlCache = new Map<object, ParsedSubscription>();
	private readonly gqlComparisonCache = new Map<object, CachedGqlComparisons>();

	private readonly subscriptions = new Map<string, TargetContext>();
	private handlerSequenceNumber = 0;

	constructor(logger: ILogger) {
		this.logger = logger.createScopedLogger('SubscriptionManagerService');
	}

	/**
	 * Create a subscription handler instance to be used for subscribing on target objects
	 */
	public createHandler(
		name: string,
		changeTypes: NodeChangeType[],
		subscriptionGql: ApolloSubscription<unknown, unknown>,
		subscribeFunc: (targetId: string) => Subscription,
		unsubscribeFunc: (targetId: string, subscription: Subscription) => void
	): SubscriptionHandler {
		const gqlInfo = this.parseGqlSubscription(subscriptionGql);

		if (this.handlerSequenceNumber >= Number.MAX_SAFE_INTEGER) {
			// Safety-reset of number sequence for completeness
			this.handlerSequenceNumber = 0;
		}

		return {
			name: `${this.handlerSequenceNumber++}:${name}.${gqlInfo.name}`,
			changeTypes: changeTypes,
			gqlSelection: gqlInfo.selection,
			gqlSelectionId: subscriptionGql.document, // (The GQL query document is a generated global constant we can use as id)
			subscribe: subscribeFunc,
			unsubscribe: unsubscribeFunc
		};
	}

	/**
	 * Add a request to subscribe to a set of GQL fields on a target object.
	 * Subscribing the same handler to the same target more than once is allowed, but will not
	 * nest. I.e. one remove will undo any number of adds.
	 * @param targetId Id of the target object to subscribe on
	 * @param handler The handler requesting the subscription (and thus also the GQL fields to subscribe to)
	 */
	public addSubscriptionRequest(targetId: string, handler: SubscriptionHandler): void {
		let target = this.subscriptions.get(targetId);
		if (!target) {
			target = {
				targetId: targetId,
				subscribers: new Set<SubscriptionHandler>(),
				activeSubscriptions: new Map<SubscriptionHandler, ActiveSubscription>()
			};
			this.subscriptions.set(targetId, target);
		}

		this.logger.information('subscribe', 'Adding subscriber to target', {
			targetId,
			handler: handler.name
		});
		target.subscribers.add(handler);

		this.evaluateSubscriptions(target);
	}

	/**
	 * Remove a request to subscribe to a set of GQL fields from a target object.
	 * @param targetId Id of the target object to retire the subscription from
	 * @param handler The handler which originally requested the subscription (and thus also the GQL fields to nolonger subscribe to)
	 */
	public removeSubscriptionRequest(targetId: string, handler: SubscriptionHandler): void {
		const target = this.subscriptions.get(targetId);
		if (target) {
			if (target.subscribers.delete(handler)) {
				this.logger.information('unsubscribe', 'Removed subscriber from target', {
					targetId,
					handler: handler.name
				});

				this.evaluateSubscriptions(target);

				if (target.subscribers.size === 0) {
					this.subscriptions.delete(targetId);
				}
			}
		}
	}

	/**
	 * DEBUG: Dump all content cached in the service to be able to inspect state
	 */
	/* Keeping this in so cache debugging can easily be re-enabled when/if needed
	public debugDumpToConsole(): void {
		this.logger.debug('dump', 'BEGIN ------------------ SubscriptionManagerService dump ------------------');
		this.logger.debug('dump', 'Subscriptions:');
		this.subscriptions.forEach((sub) => {
			this.logger.debug('dump', ` - ${sub.targetId}`);
			this.logger.debug('dump', `    Requested: ${sub.subscribers.size}`);
			sub.subscribers.forEach((handler) => {
				this.logger.debug('dump', `     - ${handler.name}`);
			});
			this.logger.debug('dump', `    Active: ${sub.activeSubscriptions.size}`);
			sub.activeSubscriptions.forEach((active) => {
				this.logger.debug('dump', `     - ${active.handler.name}`);
			});
		});

		this.logger.debug('dump', '');
		this.logger.debug('dump', 'Cached parsed gql:');
		this.parsedGqlCache.forEach((entry) => {
			this.logger.debug('dump', ` - ${entry.name}`);
		});

		this.logger.debug('dump', '');
		this.logger.debug('dump', 'Cached comparisons:');
		this.gqlComparisonCache.forEach((entry, candidateKey) => {
			const candidateSource = (<DocumentNode>candidateKey).loc?.source.body.split('\n')[1].trim() ?? '<unnamed>';
			this.logger.debug('dump', ` - ${candidateSource}`);
			entry.comparisons.forEach((comp, otherKey) => {
				const otherSource = (<DocumentNode>otherKey).loc?.source.body.split('\n')[1].trim() ?? '<unnamed>';
				const equalString =
					comp === SelectionComparison.NotContained
						? 'Disjoint'
						: comp === SelectionComparison.Equal
						  ? 'Equal'
						  : 'Contains';
				this.logger.debug('dump', `     - ${otherSource}: ${equalString}`);
			});
		});

		this.logger.debug('dump', '');
		this.logger.debug('dump', 'END ------------------ SubscriptionManagerService dump ------------------');
	}
	*/

	/**
	 * Determine which GQL subscriptions are required to actively subscribe to all requested GQL fields,
	 * and update the active GQL subscriptions to match.
	 */
	private evaluateSubscriptions(target: TargetContext): void {
		let madeChanges = false;
		const requiredSubscriptions = this.determineRequiredSubscriptions(target);

		// Close subscriptions nolonger required.
		const subsToClose: ActiveSubscription[] = [];
		for (const active of target.activeSubscriptions.values()) {
			if (!requiredSubscriptions.has(active.handler)) {
				subsToClose.push(active);
				madeChanges = true;
			}
		}
		subsToClose.forEach((s) => this.closeSubscription(target, s));

		// Open subscriptions required
		const subscToOpen: SubscriptionHandler[] = [];
		for (const required of requiredSubscriptions) {
			if (!target.activeSubscriptions.has(required)) {
				subscToOpen.push(required);
				madeChanges = true;
			}
		}
		subscToOpen.forEach((s) => this.openSubscription(target, s));

		if (madeChanges) {
			this.logger.information(
				'evaluateSubscriptions',
				`Subscriptions changed. Selected ${target.activeSubscriptions.size} of ${target.subscribers.size} requested`
			);
		}
	}

	/**
	 * Compare requested susbcriptions to determine which subscriptions are required to cover all GQL field selections
	 */
	private determineRequiredSubscriptions(target: TargetContext): Set<SubscriptionHandler> {
		const candidateSubscribers: (SubscriptionHandler | undefined)[] = [...target.subscribers];

		// Trim out subscriptions with duplicate field selections
		for (let candidateIdx = 0; candidateIdx < candidateSubscribers.length - 1; candidateIdx++) {
			const candidate = candidateSubscribers[candidateIdx];
			if (candidate) {
				for (let otherIdx = candidateIdx + 1; otherIdx < candidateSubscribers.length; otherIdx++) {
					const other = candidateSubscribers[otherIdx];
					if (other) {
						let comparison = this.isSubscriptionContainedInOther(candidate, other);

						if (comparison === SelectionComparison.Contained) {
							// Candidate is subset of other - trim it out
							candidateSubscribers[candidateIdx] = undefined;
						} else if (comparison === SelectionComparison.Equal) {
							// Candidate selection equals other - trim out one of them.
							// Candidate will be trimmed unless it is subscribed to. In that case other is trimmed to
							// avoid unneeded subscribtion changes.
							const ignoreOther = target.activeSubscriptions.has(candidate);
							const idxToIgnore = ignoreOther ? otherIdx : candidateIdx;

							candidateSubscribers[idxToIgnore] = undefined;
							if (!ignoreOther) {
								// candidate subscription was ignored - stop comparing against it
								break;
							}
						} else {
							// Candidate was not included in other - check if other is included in candidate
							comparison = this.isSubscriptionContainedInOther(other, candidate);
							if (comparison !== SelectionComparison.NotContained) {
								// Always a "other contained in candidate" case - so always "other" being trimmed
								candidateSubscribers[otherIdx] = undefined;
							}
						}
					}
				}
			}
		}
		const requiredSubscriptions = new Set<SubscriptionHandler>(
			<Array<SubscriptionHandler>>candidateSubscribers.filter((s) => s !== undefined)
		);

		return requiredSubscriptions;
	}

	private openSubscription(target: TargetContext, handler: SubscriptionHandler): void {
		this.logger.information('openSubscription', 'Subscribing', {
			targetId: target.targetId,
			handler: handler.name
		});

		const subscription = handler.subscribe(target.targetId);

		target.activeSubscriptions.set(handler, {
			handler: handler,
			subscription: subscription
		});
	}

	private closeSubscription(target: TargetContext, s: ActiveSubscription): void {
		this.logger.information('closeSubscription', 'Unsubscribing', {
			targetId: target.targetId,
			handler: s.handler.name
		});

		s.handler.unsubscribe(target.targetId, s.subscription);
		target.activeSubscriptions.delete(s.handler);
	}

	/**
	 * Get parsed-out GQL subscription data
	 */
	private parseGqlSubscription(subscriptionGql: ApolloSubscription<unknown, unknown>): ParsedSubscription {
		let parsed = this.parsedGqlCache.get(subscriptionGql.document);
		if (!parsed) {
			parsed = GqlSubscriptionParser.parseGqlSubscription(subscriptionGql.document);
			this.parsedGqlCache.set(subscriptionGql.document, parsed);
		}
		return parsed;
	}

	/**
	 * Check whether a candidate GQL subscription is contained within another GQL subscription
	 */
	private isSubscriptionContainedInOther(
		candidate: SubscriptionHandler,
		other: SubscriptionHandler
	): SelectionComparison {
		// Check NodeChangeTypes
		const changeTypeComparison = this.isNodeChangeTypesContainedInOther(candidate.changeTypes, other.changeTypes);
		if (changeTypeComparison === SelectionComparison.NotContained) {
			// ChangeTypes are not contained => the whole comparison is not contained
			return SelectionComparison.NotContained;
		}

		// Check GQL field selection
		const gqlComparison = this.isGqlSelectionContainedInOther(candidate, other);

		// Select the least positive result as overall comparison result
		if (gqlComparison === SelectionComparison.NotContained) {
			return SelectionComparison.NotContained;
		}
		if (gqlComparison === SelectionComparison.Contained || changeTypeComparison === SelectionComparison.Contained) {
			return SelectionComparison.Contained;
		}
		return SelectionComparison.Equal;
	}

	/**
	 * Check whether a candidate set of NodeChangeTypes is contained within another set of NodeChangeTypes
	 */
	private isNodeChangeTypesContainedInOther(
		candidateChangeTypes: NodeChangeType[],
		otherChangeTypes: NodeChangeType[]
	): SelectionComparison {
		let result: SelectionComparison = SelectionComparison.Equal;

		if (candidateChangeTypes.length > otherChangeTypes.length) {
			result = SelectionComparison.NotContained;
		} else {
			if (candidateChangeTypes.length < otherChangeTypes.length) {
				result = SelectionComparison.Contained;
			}

			for (const changeType of candidateChangeTypes) {
				if (!otherChangeTypes.includes(changeType)) {
					result = SelectionComparison.NotContained;
					break;
				}
			}
		}

		return result;
	}

	/**
	 * Check whether a candidate GQL selection tree is included in another GQL selection tree.
	 *
	 * Will use caching to avoid repeated full selection tree comparisons.
	 */
	private isGqlSelectionContainedInOther(
		candidate: SubscriptionHandler,
		other: SubscriptionHandler
	): SelectionComparison {
		let result: SelectionComparison | undefined;

		// Get comparison from cache
		const cachedComparisons = this.getOrAddCachedComparisonSet(candidate.gqlSelectionId);
		result = cachedComparisons.comparisons.get(other.gqlSelectionId);
		if (!result) {
			// Comparison was not cached - evaluate if candidate is included in other selection
			result = this.isSelectionTreeIncluded(candidate.gqlSelection, other.gqlSelection);
			cachedComparisons.comparisons.set(other.gqlSelectionId, result);

			if (result !== SelectionComparison.NotContained) {
				// If candidate is included in/equal to other selection we also know the opposite comparison
				// result without doing an actual field-by-field comparison. So cache that as well
				const otherComparisons = this.getOrAddCachedComparisonSet(other.gqlSelectionId);
				if (!otherComparisons.comparisons.has(candidate.gqlSelectionId)) {
					otherComparisons.comparisons.set(
						candidate.gqlSelectionId,
						result === SelectionComparison.Equal
							? SelectionComparison.Equal
							: SelectionComparison.NotContained
					);
				}
			}
		}

		return result;
	}

	/**
	 * Check whether a candidate GQL selection tree is included in another GQL selection tree
	 */
	private isSelectionTreeIncluded(candidateField: SubscribedField, otherField: SubscribedField): SelectionComparison {
		let isEqual = true;

		// Check fragments
		for (const candidateChild of candidateField.fragments) {
			const otherChild = otherField.fragments.find((f) => f.typeName === candidateChild.typeName);
			if (!otherChild) {
				return SelectionComparison.NotContained;
			}
			const fragmentComparison = this.isSelectionTreeIncluded(candidateChild, otherChild);
			if (fragmentComparison === SelectionComparison.NotContained) {
				return SelectionComparison.NotContained;
			} else if (fragmentComparison !== SelectionComparison.Equal) {
				isEqual = false;
			}
		}
		if (candidateField.fragments.length !== otherField.fragments.length) {
			isEqual = false;
		}

		// Check fields
		for (const candidateChild of candidateField.fields) {
			const otherChild = otherField.fields.find((f) => f.name === candidateChild.name);
			if (!otherChild) {
				return SelectionComparison.NotContained;
			}
			const fieldComparison = this.isSelectionTreeIncluded(candidateChild, otherChild);
			if (fieldComparison === SelectionComparison.NotContained) {
				return SelectionComparison.NotContained;
			} else if (fieldComparison !== SelectionComparison.Equal) {
				isEqual = false;
			}
		}
		if (candidateField.fields.length !== otherField.fields.length) {
			isEqual = false;
		}

		return isEqual ? SelectionComparison.Equal : SelectionComparison.Contained;
	}

	private getOrAddCachedComparisonSet(candidateKey: object): CachedGqlComparisons {
		let comparisonSet = this.gqlComparisonCache.get(candidateKey);
		if (!comparisonSet) {
			comparisonSet = {
				comparisons: new Map<object, SelectionComparison>()
			};
			this.gqlComparisonCache.set(candidateKey, comparisonSet);
		}
		return comparisonSet;
	}
}
