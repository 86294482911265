import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ILogger } from '@shure/shared/angular/utils/logging';

@Injectable({
	providedIn: 'root'
})
export class MDNSQueryService {
	private readonly logger: ILogger;

	constructor(
		logger: ILogger,
		private readonly httpClient: HttpClient
	) {
		this.logger = logger.createScopedLogger('MDNSQueryService');
	}

	public scanViaHTTPGet(mDnsHostName: string): void {
		this.logger.trace('scanViaHTTPGet()', mDnsHostName);
		this.httpClient
			.get(`https://${mDnsHostName}`)
			.pipe(catchError((_err) => EMPTY))
			.subscribe({
				next: (result) => {
					this.logger.trace('scanViaHTTPGet result', `https://${mDnsHostName}`, result);
				},
				error: (error) => {
					this.logger.error('scanViaHTTPGet', 'error', JSON.stringify(error));
				}
			});
	}
}
