import { Injectable } from '@angular/core';

import { InventoryDevice } from '@shure/cloud/shared/models/devices';

import { DeviceRelationshipService } from '../api/device-relationship.service';

@Injectable({ providedIn: 'root' })
export class SysApiDeviceRelationshipService extends DeviceRelationshipService {
	private proxyToHostMap = new Map<string, string>();

	public registerProxiedDevices(hostDevice: InventoryDevice): void {
		const proxiedDevices = hostDevice.features.proxiedDevices?.proxiedDevices ?? [];
		for (const proxiedDevice of proxiedDevices) {
			this.proxyToHostMap.set(proxiedDevice.id, hostDevice.id);
		}
	}

	public removeDevice(deviceId: string): void {
		// Remove all associations for this device ID, whether it is a proxy or host device
		for (const entry of this.proxyToHostMap.entries()) {
			if (entry[0] === deviceId || entry[1] === deviceId) {
				this.proxyToHostMap.delete(entry[0]);
			}
		}
	}

	public getHostDevice(proxiedDeviceId: string): string | undefined {
		return this.proxyToHostMap.get(proxiedDeviceId);
	}
}
