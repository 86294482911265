import { Injectable, Inject } from '@angular/core';
import { catchError, map, Observable, Subject, Subscription, takeUntil, tap, throwError } from 'rxjs';

import { SubscriptionManager, SubscriptionManagerConfigCreate } from '@shure/cloud/shared/apollo';
import { FirmwarePackage, PropertyPanelDevice } from '@shure/cloud/shared/models/devices';
import { UpdateResponse } from '@shure/cloud/shared/models/http';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { ApolloQueryErrorMapper } from '@shure/shared/angular/data-access/system-api/core';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { CloudDeviceApiService } from '../api/cloud-device-api.service';
import { DevicePropertyPanelApiService } from '../api/device-property-panel-api.service';

import {
	PropertyPanelDeviceQueryGQL,
	PropertyPanelDeviceSubscriptionGQL,
	NodeChangeType
} from './graphql/generated/cloud-sys-api';
import { mapPropertyPanelDeviceFromSysApi } from './mappers/map-property-panel-device';

@Injectable({ providedIn: 'root' })
export class SysApiDevicePropertyPanelApiService extends DevicePropertyPanelApiService {
	private destroy$ = new Subject<void>();
	private readonly logger: ILogger;

	private readonly devicesGqlSubscriptions = new SubscriptionManager({
		subscriptionType: 'property-panel',
		create: (config): Subscription => this.createGqlSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	constructor(
		logger: ILogger,
		private readonly cloudDeviceService: CloudDeviceApiService,
		private readonly propertyPanelDeviceQueryGQL: PropertyPanelDeviceQueryGQL,
		private readonly propertyPanelDeviceSubscriptionGQL: PropertyPanelDeviceSubscriptionGQL,
		private readonly oktaService: OktaInterfaceService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {
		super();
		this.logger = logger.createScopedLogger('DaiDevicePropertyPanelService');

		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});
	}

	/**
	 * Get device by id
	 * @param deviceId
	 * @returns
	 */
	public getDevice$(deviceId: string): Observable<PropertyPanelDevice> {
		this.logger.trace('getDevice$()', 'propertyPanelDeviceQueryGQL', { deviceId });

		return this.propertyPanelDeviceQueryGQL
			.watch(
				{
					nodeId: deviceId,
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false,
					requestProxiedDevices: !!this.appEnv.showProxiedDevices
				},
				{
					errorPolicy: 'ignore',
					returnPartialData: true,
					fetchPolicy: 'cache-only'
				}
			)
			.valueChanges.pipe(
				map((query) => {
					if (query.data.node && 'isDeviceNode' in query.data.node) {
						const device = mapPropertyPanelDeviceFromSysApi(query.data.node);
						this.logger.trace('getDevice$()', 'Done', device);
						return device;
					}
					this.logger.error(
						'getDevice$()',
						'Failed to query propertypanel device',
						JSON.stringify({ deviceId })
					);
					throw ApolloQueryErrorMapper.getError(query);
				}),
				// establish the per-device nodeChanges subscription, if not already established.
				tap((device) => this.devicesGqlSubscriptions.register([device.id])),
				catchError((error: Error) => {
					this.logger.error(
						'getDevice$()',
						'Failed to query property panel device',
						JSON.stringify({
							deviceId,
							error
						})
					);
					return throwError(() => error);
				})
			);
	}

	/**
	 * Tell the service to "forget" about this device. Includes unsubscribing to
	 * the more detailed property subscriptions.
	 * @param nodeId
	 */
	public forgetDevice(nodeId: string): void {
		this.devicesGqlSubscriptions.deregister(nodeId);
	}

	/**
	 * Set mute for a device.
	 * @param deviceId
	 * @param mute
	 * @returns
	 */
	public setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setMute()', 'Setting mute', { deviceId, mute });
		return this.cloudDeviceService.setMute(deviceId, mute);
	}

	/**
	 * Set device name.
	 * @param deviceId
	 * @param name
	 * @returns
	 */
	public setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setDeviceName()', 'Setting device name', JSON.stringify({ deviceId, name }));
		return this.cloudDeviceService.setDeviceName(deviceId, name);
	}

	/**
	 * Set device identifying state.
	 * @param deviceId
	 * @param identify
	 * @returns
	 */
	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setIdentify()', 'Setting identify', { deviceId, identify });
		return this.cloudDeviceService.setIdentify(deviceId, identify);
	}

	/**
	 * Reboot a device
	 * @param deviceId
	 * @returns
	 */
	public rebootDevice(deviceId: string): Observable<UpdateResponse<void, string>> {
		this.logger.trace('rebootDevice()', 'Rebooting device', { deviceId });
		return this.cloudDeviceService.rebootDevice$(deviceId);
	}

	/**
	 * Request a device firmware update
	 * @param deviceId
	 * @param firmwarePkg
	 * @returns
	 */
	public override updateFirmware(
		deviceId: string,
		firmwarePkg: FirmwarePackage
	): Observable<UpdateResponse<void, string>> {
		this.logger.trace('updateFirmware()', 'Updating firmware', { deviceId, package: firmwarePkg });
		return this.cloudDeviceService.updateFirmware$([
			{ id: deviceId, firmwarePackageKey: firmwarePkg.key, firmwarePackageVersion: firmwarePkg.version }
		]);
	}

	private initService(): void {
		this.logger.information('initService', 'user logged in, initializating service');
		this.destroy$ = new Subject();
	}

	private suspendService(): void {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.destroy$.next();
		this.destroy$.complete();
		this.devicesGqlSubscriptions.deregisterAll();
	}

	private createGqlSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		const subscriptionTypes = [
			NodeChangeType.DeviceName,
			NodeChangeType.DeviceIdentify,
			NodeChangeType.DeviceAudioMute,
			NodeChangeType.DeviceUptime,
			NodeChangeType.DeviceAvailablePackages,
			NodeChangeType.DeviceDoubleStuffProxiedTransmitters,
			NodeChangeType.DeviceBatteryLevel,
			NodeChangeType.DeviceMicStatus,
			NodeChangeType.DeviceProxiedDevices
		];

		return this.propertyPanelDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: subscriptionTypes,
					requestFirmwareFields: this.appEnv.showFirmwareUpgrade ?? false,
					requestProxiedDevices: !!this.appEnv.showProxiedDevices
				},
				{ fetchPolicy: 'network-only' }
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (change) => {
					this.logger.trace('propertyPanelDeviceSubscriptionGQL', 'Received update', {
						id,
						change
					});
				},
				complete: () => {
					this.logger.debug('propertyPanelDeviceSubscriptionGQL', 'Completed', {
						id
					});
				},
				error: (error) => {
					this.logger.error(
						'propertyPanelDeviceSubscriptionGQL',
						'Encountered error',
						JSON.stringify({
							id,
							error
						})
					);
					retryCallback();
				}
			});
	}
}
