import { Injectable, OnDestroy } from '@angular/core';
import {
	BehaviorSubject,
	Observable,
	ReplaySubject,
	Subject,
	catchError,
	filter,
	map,
	of,
	take,
	takeUntil,
	throwError
} from 'rxjs';
import { DateTime } from 'ts-luxon';

import { DeviceDetection } from '@shure/cloud/shared/models/devices';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { MDNSQueryService } from '@shure/cloud/shared/services/mdns-query';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DeviceClaimingDiscoveryApiService } from '../api/device-claiming-discovery-api.service';

import {
	DetectedDeviceGQL,
	DetectedDeviceVariables,
	OnDetectedDevicesGQL,
	OnDetectedDevicesVariables
} from './graphql/generated/cloud-sys-api';
import { GqlUtils } from './mappers/gql.utils';
import { SysApiDetectionTokenApiService } from './sys-api-detection-token-api.service';

//
// Once this service is started, it lives for the life of the application.
//
@Injectable()
export class SysApiDeviceClaimingDiscoveryApiService implements DeviceClaimingDiscoveryApiService, OnDestroy {
	public unclaimedDevices$ = new BehaviorSubject<DeviceDetection[]>([]);
	public scanInProgess$ = new BehaviorSubject<boolean>(false);
	public deviceDiscoveryError$ = new ReplaySubject<string>(1);

	private destroy$ = new Subject<void>();
	private readonly logger: ILogger;
	private auditUnclaimedDevicesTimer: ReturnType<typeof setTimeout> | undefined;

	constructor(
		logger: ILogger,
		private readonly mdnsQuery: MDNSQueryService,
		private readonly generateDetectionToken: SysApiDetectionTokenApiService,
		private readonly detectedDevicesGQL: DetectedDeviceGQL,
		private readonly onDetectedDevicesGQL: OnDetectedDevicesGQL,
		private readonly oktaService: OktaInterfaceService
	) {
		this.logger = logger.createScopedLogger('SysApiDeviceClaimingDiscoveryApiService');

		monitorLoginState(this.oktaService, {
			// no need for a onLogin handler since users of the service invoke newScan() to start things off.
			onLogOut: this.destroy.bind(this)
		});
	}

	public ngOnDestroy(): void {
		this.scanInProgess$.next(false);
		clearTimeout(<number | undefined>this.auditUnclaimedDevicesTimer);
		this.destroy$.next();
		this.destroy$.complete();
	}

	public destroy(): void {
		this.logger.debug('destroy()', 'scan in progress: ', this.scanInProgess$.value);
		this.ngOnDestroy();
	}

	public newScan(): void {
		this.logger.trace('newScan()', 'Scan started for unclaimed devices', this.scanInProgess$.value);
		if (this.scanInProgess$.value === true) {
			return;
		}

		this.scanInProgess$.next(true);
		this.unclaimedDevices$.next([]);
		this.destroy$ = new Subject();

		this.generateDetectionToken
			.detectionToken$()
			.pipe(
				take(1),
				map((result) => {
					this.logger.trace('newScan()', 'Detection token received', result);
					// temporarily not using the subscription. Normally, this should be removed
					// but we intend to add it back once we've implemented the subscription Ack
					// capability
					// this.issueDetectionEventSubscription(result.token);
					this.mdnsQuery.scanViaHTTPGet(result.token + '.local');
					this.issueDetectionEventQuery(result.token);
					this.scheduleDetectionTokenAudit(result.expiresAt);
				}),
				catchError((error) => {
					this.logger.error('newScan', 'Error', JSON.stringify(error));
					return of({ error: error.message });
				})
			)
			.subscribe();
	}

	private issueDetectionEventQuery(token: string): void {
		this.logger.trace('issueDetectionEventQuery()', 'Unclaimed device detection query', { token });

		this.unclaimedDevicesQuery$(token)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (detectedDeviceInfo) => {
					detectedDeviceInfo.forEach((detectionEvent) => {
						this.handleNewDeviceDetection(detectionEvent);
					});
				},
				error: (error) => {
					this.deviceDiscoveryError$.next(error);
				}
			});
	}

	private issueDetectionEventSubscription(token: string): void {
		this.unclaimedDevicesSubscription$(token)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (detectionEvent) => {
					this.handleNewDeviceDetection(detectionEvent);
				},
				error: (error) => {
					this.deviceDiscoveryError$.next(error);
				}
			});
	}

	private handleNewDeviceDetection(deviceDetection: DeviceDetection | undefined): void {
		this.logger.trace('handleNewDetectionEvent', 'Handle detection of claimable device', deviceDetection);

		if (deviceDetection && deviceDetection?.claimable) {
			const currentDevices = this.unclaimedDevices$.value;
			const index = currentDevices.findIndex((obj) => obj.device.id === deviceDetection.device.id);
			if (index >= 0) {
				currentDevices[index] = deviceDetection;
			} else {
				currentDevices.push(deviceDetection);
			}
			this.unclaimedDevices$.next([...currentDevices]);
		}
	}

	private scheduleDetectionTokenAudit(expiresAt: string): void {
		const timeout =
			Math.max(DateTime.fromISO(expiresAt).toUnixInteger() - DateTime.now().toUnixInteger(), 2) * 1000;
		this.logger.trace('scheduleDetectionTokenAudit', 'Claiming timer audit', {
			timeout
		});

		this.auditUnclaimedDevicesTimer = setTimeout(() => {
			this.destroy();
			this.newScan();
		}, timeout);
	}

	/**
	 *
	 * @param token: the current detection token devices may be responding to.
	 * @returns An observable to the query result.
	 *
	 * Note: we're using a fetchPolicy of 'no-cache' becuase we don't need these
	 *       query results in the apollo cache, taking up space.
	 * See:
	 *    https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
	 * for details
	 */
	private unclaimedDevicesQuery$(token: string): Observable<DeviceDetection[]> {
		const fetchVar: DetectedDeviceVariables = {
			tokenId: token
		};
		return this.detectedDevicesGQL
			.watch(fetchVar, {
				fetchPolicy: 'no-cache',
				pollInterval: 2000
			})
			.valueChanges.pipe(
				map((result) => {
					if (GqlUtils.isSuccess(result)) {
						return result.data?.detectedDevices;
					}
					const err = GqlUtils.decomposeGQLError(result);
					this.logger.error('unclaimedDevicesQuery$', 'Fetch error', JSON.stringify(err));
					throwError(() => err);
					return [];
				}),

				catchError((error) => {
					this.logger.error('unclaimedDevicesQuery$', 'Error', JSON.stringify(error));
					return throwError(() => error);
				}),
				takeUntil(this.destroy$)
			);
	}

	/**
	 *
	 * @param token: the current detection token devices may be responding to.
	 * @returns Observable to stream of token detected devices.
	 *
	 * Note: we're using a fetchPolicy of 'no-cache' becuase we don't need these
	 *       query results in the apollo cache, taking up space.
	 */
	private unclaimedDevicesSubscription$(token: string): Observable<DeviceDetection | undefined> {
		const subscriptionVar: OnDetectedDevicesVariables = {
			tokenId: token
		};
		return this.onDetectedDevicesGQL
			.subscribe(subscriptionVar, {
				fetchPolicy: 'no-cache'
			})
			.pipe(
				filter((result) => !!result.data),
				filter((result) => !!result.data?.detectedDevices),
				map((result) => {
					return result.data?.detectedDevices;
				}),

				catchError((error) => {
					this.logger.error('unclaimedDevicesSubscription$', 'Error', JSON.stringify(error));
					return throwError(() => error);
				})
			);
	}
}
