import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, mergeScan, take, takeUntil } from 'rxjs/operators';

import {
	ClaimResults,
	DeletePendingClaimBatchResult,
	DeletePendingClaimResult,
	DeviceClaimResult,
	DeviceDetection,
	PendingClaim,
	DeviceClaimProgress,
	MacSerialNumberDevice,
	PendingClaimStatus
} from '@shure/cloud/shared/models/devices';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { ErrorCode } from '@shure/shared/angular/data-access/system-api/core';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { DeviceClaimingApiService } from '../api/device-claiming-api.service';

import {
	ClaimDeviceByMacSerialNumberGQL,
	ClaimDeviceByMacSerialNumberOpResult,
	ClaimDeviceByMacSerialNumberVariables,
	ClaimDeviceGQL,
	ClaimDeviceVariables,
	DeletePendingClaimGQL,
	DeletePendingClaimVariables,
	ListPendingClaimsGQL,
	PendingClaimStatusGQL,
	UnclaimDeviceGQL,
	UnclaimDeviceVariables
} from './graphql/generated/cloud-sys-api';
import { GqlUtils } from './mappers/gql.utils';

const claimByMacSsnBatchSize = 50;

/**
 * This service is provided in the root/app module. Once instantiated, it will always exist.
 */
@Injectable({
	providedIn: 'root'
})
export class SysApiDeviceClaimingApiService implements DeviceClaimingApiService {
	public claimOpInProgress$ = new BehaviorSubject<boolean>(false);
	private pendingClaims$ = new BehaviorSubject<PendingClaim[]>([]);
	private readonly logger: ILogger;
	private destroy$ = new Subject<void>();

	private claimingResults: ClaimResults = {
		numSuccess: 0,
		numError: 0,
		errors: []
	};

	constructor(
		logger: ILogger,
		private claimDeviceGQL: ClaimDeviceGQL,
		private unclaimDeviceGQL: UnclaimDeviceGQL,
		private listPendingClaimGQL: ListPendingClaimsGQL,
		private deletePendingClaimGQL: DeletePendingClaimGQL,
		private claimDeviceMacSerialGQL: ClaimDeviceByMacSerialNumberGQL,
		private pendingClaimStatusGQL: PendingClaimStatusGQL,
		private readonly oktaService: OktaInterfaceService
	) {
		this.logger = logger.createScopedLogger('SysApiDeviceClaimingApiService');
		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});
	}

	public claimDevicesThrottled$(devices: DeviceDetection[]): Observable<DeviceClaimProgress<DeviceDetection>> {
		const progress: DeviceClaimProgress<DeviceDetection> = {
			done: devices.length === 0, // if no devices, we're alreday done.
			stats: {
				totalToDo: devices.length,
				success: 0,
				inProgress: 0,
				failed: 0
			},
			devices: []
		};
		const throttle$ = new Subject<DeviceDetection[]>();

		return new Observable<DeviceClaimProgress<DeviceDetection>>((observer) => {
			throttle$.subscribe((deviceClaimBatch) => {
				if (progress.done) {
					observer.next(progress);
					observer.complete();
					throttle$.complete();
					this.claimOpInProgress$.next(false);
					return;
				}

				deviceClaimBatch.forEach((currentDevice) => {
					this.updateClaimingResultsByDevice(progress, currentDevice, 'in-progress');
					observer.next(progress);

					this.claimDeviceMutation$(currentDevice.device.id, currentDevice.detectionToken)

						// eslint-disable-next-line rxjs/no-nested-subscribe
						.subscribe({
							next: () => {
								this.updateClaimingResultsByDevice(progress, currentDevice, 'success');
								observer.next(progress);
								const nextDevice = devices.splice(0, 1); // next claim
								throttle$.next(nextDevice);
							},
							error: () => {
								this.updateClaimingResultsByDevice(progress, currentDevice, 'error');
								observer.next(progress);
								const nextDevice = devices.splice(0, 1); // next claim
								throttle$.next(nextDevice);
							}
						});
				});
			});

			// Submit 10 requests concurrently. As each one completes, submit another.
			const thisBatch = devices.splice(0, 10);
			this.claimOpInProgress$.next(true);
			observer.next(progress);
			throttle$.next(thisBatch);
		});
	}

	public claimDevicesByMacSerialThrottled$(
		macSerialDevices: MacSerialNumberDevice[]
	): Observable<DeviceClaimProgress<MacSerialNumberDevice>> {
		const retVal$ = new Subject<DeviceClaimProgress<MacSerialNumberDevice>>();
		const progress: DeviceClaimProgress<MacSerialNumberDevice> = {
			done: false,
			stats: {
				totalToDo: macSerialDevices.length,
				success: 0,
				inProgress: 0,
				failed: 0
			},
			devices: []
		};

		const throttle$ = new Subject<MacSerialNumberDevice[]>();

		// let everyone know a claim operation is in progress, and emit the initial progress
		retVal$.next(progress);
		this.claimOpInProgress$.next(true);

		throttle$.subscribe((macSsnRemaining) => {
			// if we've sent all of the requests, clenaup and emit final progress value.
			if (macSsnRemaining.length === 0) {
				this.claimOpInProgress$.next(false);
				progress.done = true;
				retVal$.next(progress);
				retVal$.complete();
				throttle$.complete();
				return;
			}

			// get the next batch of devices to send
			const macSsnBatch = macSsnRemaining.splice(0, claimByMacSsnBatchSize);

			// indicate claiming is in progress for each mac/ssn value.
			macSsnBatch.forEach((item) => {
				this.updateClaimingResultsByDevice(progress, item, 'in-progress');
				retVal$.next(progress);
			});

			// now claim as a batch
			// eslint-disable-next-line rxjs/no-nested-subscribe
			this.claimDeviceByMacSerialMutation$(macSsnBatch).subscribe((result) => {
				// expecting the same number of entries in the response as in the macSsnBatch
				if (macSsnBatch.length !== result?.claimDeviceUsingMacAndSerial.length) {
					this.logger.error('claimDevicesByMacSerialThrottled$', 'Wrong number of entries in response', {
						macSsnBatch,
						result
					});
				}

				// for each macSsn value, find the value in the response and update the progress.
				// if we can't find an entry, mark it as an error... the check above should have flagged
				// the size mismatch.
				macSsnBatch.forEach((macSsnItem) => {
					const responseItem = result?.claimDeviceUsingMacAndSerial.find(
						(resultItem) =>
							macSsnItem.device.macAddress === resultItem.macAndSerialClaim.macAddress &&
							macSsnItem.device.serialNumber === resultItem.macAndSerialClaim.serialNumber
					);
					const claimResult = responseItem !== undefined && responseItem.error === null ? 'success' : 'error';
					this.updateClaimingResultsByDevice(progress, macSsnItem, claimResult);
					retVal$.next(progress);
				});
				throttle$.next(macSsnRemaining);
			});
		});

		throttle$.next(macSerialDevices);
		return retVal$.asObservable();
	}

	public unClaimDevicesThrottled$(deviceNodeIds: string[]): Observable<ClaimResults> {
		let numUnclaimsLeft = deviceNodeIds.length;
		this.logger.debug('unclaiming starting', `Num devices to unclaim: ${deviceNodeIds.length}`);
		this.claimingResults = {
			numSuccess: 0,
			numError: 0,
			errors: []
		};

		const throttle$ = new Subject<string[]>();

		return new Observable<ClaimResults>((observer) => {
			throttle$.subscribe((unclaimBatch) => {
				if (numUnclaimsLeft <= 0) {
					this.claimOpInProgress$.next(false);
					observer.next(this.claimingResults);
					observer.complete();
					throttle$.complete();
					this.logger.debug('unclaiming completed', `${this.claimingResults}`);
					return;
				}

				unclaimBatch.forEach((deviceNodeId) => {
					this.unclaimDeviceMutation$(deviceNodeId)
						.pipe(take(1))
						// eslint-disable-next-line rxjs/no-nested-subscribe
						.subscribe({
							next: () => {
								++this.claimingResults.numSuccess;
								numUnclaimsLeft--;
								const nextDevice = deviceNodeIds.splice(0, 1); // next unclaim
								throttle$.next(nextDevice);
							},
							error: (error) => {
								++this.claimingResults.numError;
								numUnclaimsLeft--;
								this.claimingResults.errors.push(error);
								const nextDevice = deviceNodeIds.splice(0, 1); // next unclaim
								throttle$.next(nextDevice);
							}
						});
				});
			});

			// Submit 10 requests concurrently. As each one completes, submit another.
			this.claimOpInProgress$.next(true);
			const thisBatch = deviceNodeIds.splice(0, 10);
			throttle$.next(thisBatch);
		});
	}

	/**
	 * Return an observable for the pendingClaims subject containing all pending claims
	 */
	public getPendingClaims$(): Observable<PendingClaim[]> {
		return this.pendingClaims$.asObservable();
	}

	/**
	 * Delete pending claims concurrently
	 * @returns an observable that sends updates on the progress of the deletions
	 */
	public deletePendingClaimsThrottled$(deviceNodeIds: string[]): Observable<DeletePendingClaimBatchResult> {
		const batchSize = 10; // concurrency
		this.claimOpInProgress$.next(true);
		// execute up to 10 in parallel. blocking. at least one should complete before another is pulled in
		return from(deviceNodeIds).pipe(
			mergeMap(
				(device) =>
					this.deletePendingClaimMutation$(device).pipe(
						map((_res) => ({
							numSuccess: 1,
							numError: 0,
							errors: []
						})),
						catchError((err) =>
							of({
								numSuccess: 0,
								numError: 1,
								errors: [err]
							})
						)
					),
				batchSize
			),
			mergeScan(
				(acc, val) =>
					of({
						...acc,
						numSuccess: acc.numSuccess + val.numSuccess,
						numError: acc.numError + val.numError,
						errors: acc.errors.concat(val.errors)
					}),
				<DeletePendingClaimBatchResult>{
					total: deviceNodeIds.length,
					numSuccess: 0,
					numError: 0,
					errors: []
				}
			),
			finalize(() => {
				this.claimOpInProgress$.next(false);
			})
		);
	}

	/**
	 * Call the pending claims query and repopulate the list in pendingClaims$
	 */
	private fetchPendingClaims(bypassCache = false): void {
		// don't need to cleanup here. should complete on it's own
		this.listPendingClaimsQuery$(bypassCache).subscribe({
			next: (claims) => {
				this.pendingClaims$.next(claims);
			},
			complete: () => {
				this.logger.information('fetchPendingClaims()', 'listPendingClaimsQuery$ complete');
			}
		});
	}

	/**
	 * Uses the pending claim status subscription to get updates on pending claims and
	 * pushes those updates into pendingClaims$
	 */
	private createPendingClaimSubscription(): void {
		this.pendingClaimStatusGQL
			.subscribe()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (event) => {
					if (event.data) {
						const pendingClaimStatus = event.data.pendingClaims satisfies PendingClaimStatus;
						let pendingClaims = this.pendingClaims$.value;
						const claimIndex = pendingClaims.findIndex((claim) => claim.id === pendingClaimStatus.id);
						const { status, ...pendingClaim } = pendingClaimStatus;
						switch (status) {
							case 'CREATED':
								pendingClaims = pendingClaims.concat(pendingClaim);
								break;
							case 'DELETED':
								// cannot mutate due to reference to pendingClaims$.value
								pendingClaims = pendingClaims
									.slice(0, claimIndex)
									.concat(pendingClaims.slice(claimIndex + 1));
								break;
							default:
								this.logger.error(
									'handleSignInTransition().pendingClaimSubscription',
									'subscription error',
									{
										id: pendingClaim.id
									}
								);
						}
						this.pendingClaims$.next(pendingClaims);
					}
				},
				error: (error) => {
					this.logger.error(
						'createPendingClaimSubscription().pendingClaimStatusGQL',
						'subscription error',
						JSON.stringify(error)
					);
				},
				complete: () => {
					this.logger.information(
						'createPendingClaimSubscription().pendingClaimStatusGQL',
						'subscription completed'
					);
				}
			});
	}

	/**
	 * Setup method
	 */
	private initService(): void {
		this.destroy$ = new Subject();
		this.createPendingClaimSubscription();
		this.fetchPendingClaims();
	}

	/**
	 * Cleanup method for any subscriptions
	 */
	private suspendService(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	private listPendingClaimsQuery$(bypassCache: boolean): Observable<PendingClaim[]> {
		return this.listPendingClaimGQL
			.fetch(
				{},
				{
					fetchPolicy: bypassCache ? 'no-cache' : 'cache-first'
				}
			)
			.pipe(
				map((result) => {
					if (GqlUtils.isSuccess(result)) {
						this.logger.trace('listPendingClaimsQuery', 'Success', { result });
						return <PendingClaim[]>result.data?.pendingDeviceClaims;
					}
					const err = GqlUtils.decomposeGQLError(result);
					this.logger.error('listPendingClaimsQuery', 'Failure', JSON.stringify(err));
					throwError(() => err);
					return [];
				}),
				catchError((error) => {
					this.logger.error('listPendingClaimsQuery', 'Error', JSON.stringify(error));
					return throwError(() => error);
				})
			);
	}

	private deletePendingClaimMutation$(id: string): Observable<DeletePendingClaimResult> {
		const mutationVars: DeletePendingClaimVariables = {
			pendingClaimId: id
		};

		return this.deletePendingClaimGQL.mutate(mutationVars).pipe(
			take(1),
			map((result) => {
				if (result.data && result.data?.deletePendingDeviceClaim.error === null) {
					this.logger.trace('deletePendingClaimMutation$', 'Success', { result });
					return result.data.deletePendingDeviceClaim satisfies DeletePendingClaimResult;
				}
				const err = GqlUtils.decomposeGQLError(result);
				this.logger.error('deletePendingClaimMutation$', 'Failure', JSON.stringify(err));
				throwError(() => err);
				return {
					id,
					error: { code: 'UNKNOWN', message: err }
				};
			}),
			catchError((error) => {
				this.logger.error('deletePendingClaimMutation$', 'Error', JSON.stringify(error));
				return throwError(() => error);
			})
		);
	}

	private updateClaimingResultsByDevice(
		progress: DeviceClaimProgress,
		device: DeviceDetection | MacSerialNumberDevice,
		result: DeviceClaimResult
	): void {
		if (result === 'in-progress') {
			progress.stats.inProgress += 1;
			progress.devices.push({
				result: 'in-progress',
				device: device
			});
			return;
		}

		// otherwise, we need to find the device entry in the array, and update the result value.
		if (result === 'success') progress.stats.success += 1;
		if (result === 'error') progress.stats.failed += 1;
		const thisDevice = progress.devices.find((d) => d.device.device.id === device.device.id);
		if (thisDevice !== undefined) {
			thisDevice.result = result;
		}

		if (progress.stats.totalToDo <= progress.stats.failed + progress.stats.success) {
			progress.done = true;
		}
	}

	private claimDeviceMutation$(deviceNodeId: string, detectionToken: string): Observable<boolean> {
		this.logger.trace('claimDeviceMutation$', 'Device claiming mutation request', deviceNodeId);
		const mutationVars: ClaimDeviceVariables = {
			deviceNodeId: deviceNodeId,
			tokenId: detectionToken
		};

		return this.claimDeviceGQL.mutate(mutationVars).pipe(
			map((result) => {
				if (GqlUtils.isSuccess(result)) {
					this.logger.trace('claimDeviceMutation$', 'Success', { result });
					return <boolean>result.data?.claimDevice;
				}
				const err = GqlUtils.decomposeGQLError(result);
				this.logger.error('claimDeviceMutation$', 'Failure', JSON.stringify(err));
				throwError(() => err);
				return false;
			}),
			catchError((error) => {
				this.logger.error('claimDeviceMutation$', 'Error', JSON.stringify({ error }));
				return throwError(() => error);
			})
		);
	}

	private claimDeviceByMacSerialMutation$(
		devices: MacSerialNumberDevice[]
	): Observable<ClaimDeviceByMacSerialNumberOpResult> {
		this.logger.trace('claimDeviceByMacSerialMutation$', 'Device claiming mutation request', { devices });

		const mutationVars: ClaimDeviceByMacSerialNumberVariables = {
			claimRequests: devices.map((d) => ({
				macAddress: d.device.macAddress,
				serialNumber: d.device.serialNumber
			}))
		};

		return this.claimDeviceMacSerialGQL.mutate(mutationVars).pipe(
			map((result) => {
				if (GqlUtils.isSuccess(result) && result.data?.claimDeviceUsingMacAndSerial !== null) {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					return result.data!;
				}
				return this.makeClaimErrorOpResult(devices, { code: ErrorCode.Unknown, message: 'unknown' });
			}),
			catchError((_error) => {
				this.logger.error('claimDeviceMacSerialGQL', 'Error', JSON.stringify(_error));
				return of(this.makeClaimErrorOpResult(devices, { code: ErrorCode.Unknown, message: 'Error Caught' }));
			})
		);
	}

	// this is a convenience function to walk the list of mac/serial values and mark it as an error.
	private makeClaimErrorOpResult(
		devices: MacSerialNumberDevice[],
		error: { code: ErrorCode; message: string }
	): ClaimDeviceByMacSerialNumberOpResult {
		return {
			claimDeviceUsingMacAndSerial: devices.map((d) => ({
				macAndSerialClaim: {
					macAddress: d.device.macAddress,
					serialNumber: d.device.serialNumber
				},
				error: error
			}))
		};
	}

	private unclaimDeviceMutation$(deviceNodeId: string): Observable<boolean> {
		this.logger.trace('unclaimDeviceMutation$', 'Device unclaiming mutation request', deviceNodeId);
		const mutationVars: UnclaimDeviceVariables = {
			deviceNodeId: deviceNodeId
		};

		return this.unclaimDeviceGQL.mutate(mutationVars).pipe(
			map((result) => {
				if (GqlUtils.isSuccess(result)) {
					this.logger.trace('unclaimDeviceMutation$', 'Success', { result });
					return <boolean>result.data?.unclaimDevice;
				}
				const err = GqlUtils.decomposeGQLError(result);
				this.logger.error('unclaimDeviceMutation$', 'Failure', JSON.stringify(err));
				throwError(() => err);
				return false;
			}),
			catchError((error) => {
				this.logger.error('unclaimDeviceMutation$', 'Error', JSON.stringify(error));
				return throwError(() => error);
			})
		);
	}
}
