import { DateTime } from 'ts-luxon';

import { LicenseInfo, LicenseState } from '@shure/cloud/shared/models/devices';

import { DeviceLicenseInfoFragment, LicenseState as SysApiLicenseState } from '../graphql/generated/cloud-sys-api';

export function mapLicenseInfoFromSysApi(licenseInfos: DeviceLicenseInfoFragment[]): LicenseInfo | undefined {
	const validLicense = licenseInfos.find((license) => license.state === SysApiLicenseState.Valid);
	if (!validLicense) {
		return undefined;
	}

	return {
		state: mapLicenseStateFromSysApi(validLicense.state),
		count: validLicense.count,
		expirationDate: validLicense.expirationDate ? DateTime.fromISO(validLicense.expirationDate) : undefined
	};
}

function mapLicenseStateFromSysApi(state: SysApiLicenseState): LicenseState {
	return <LicenseState>state;
}
