import { Signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { InventoryDevice } from '@shure/cloud/shared/models/devices';
import { UpdateResponse } from '@shure/cloud/shared/models/http';

export abstract class InventoryDevicesApiService {
	public abstract deviceInventoryQueryInProgress$: BehaviorSubject<boolean>;
	public abstract deviceInventory$: BehaviorSubject<InventoryDevice[]>;

	/**
	 * Loading state for inventory devices.
	 */
	public abstract deviceInventoryLoading: Signal<boolean>;

	/**
	 * Get # of inventory devices.
	 */
	public abstract getInventoryDevicesCount$(): Observable<number>;

	/**
	 * Get all inventory devices.
	 */
	public abstract getInventoryDevices$(): Observable<InventoryDevice[]>;

	/**
	 * Get inventory device by ID.
	 */
	public abstract getInventoryDevice$(deviceId: string): Observable<InventoryDevice>;

	/**
	 * Set mute for a device.
	 */
	public abstract setMute(deviceId: string, mute: boolean): Observable<UpdateResponse<void, string>>;

	/**
	 * Set identify for a device.
	 */
	public abstract setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>>;

	/**
	 * Set device name.
	 */
	public abstract setDeviceName(deviceId: string, name: string): Observable<UpdateResponse<void, string>>;
}
