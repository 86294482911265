import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { of } from 'rxjs';

import { ILogger } from '@shure/shared/angular/utils/logging';

import result from '../generated/system-api.generated';

import { ApolloCacheFactory } from './apollo-cache-factory.service';
import { APOLLO_LINK_PROVIDERS, ApolloLinkProvider, sortLinkProvidersByPriority } from './apollo-link.provider';
import { HttpApolloConnectionService } from './http-apollo-connection.service';

export const SYS_API_URL = new InjectionToken<{ url: string; apiKey: string }>('SYS_API_URL');

@Injectable()
export class SysApiHttpConnectionService extends HttpApolloConnectionService {
	constructor(
		logger: ILogger,
		@Inject(SYS_API_URL) connectInfo: { url: string; apiKey: string },
		apolloCacheFactory: ApolloCacheFactory,
		@Optional() @Inject(APOLLO_LINK_PROVIDERS) linkProviders: ApolloLinkProvider[]
	) {
		super(
			logger,
			'sysApi',
			result.possibleTypes,
			4,
			of(connectInfo),
			apolloCacheFactory,
			sortLinkProvidersByPriority(linkProviders ?? [])
		);
	}
}
